import Vue from 'vue'
import store from '@/store'

Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})


Vue.filter('money', function (value) {
    if(!value) return `${parseFloat(0).toFixed(2)}€`;
    return `${parseFloat(value).toFixed(2)}€`
})
