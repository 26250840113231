import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'spash',
        component: () => import('./views/Splash.vue')
    },
    {
        path: '/reservas',
        name: 'reservas',
        component: () => import('./views/Reservas')
    },
    {
        path: '/centros',
        name: 'centros',
        component: () => import('./views/Centros.vue')
    },
    {
        path: '/centro/:slug',
        name: 'centro',
        component: () => import('./views/Centro.vue')
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('./views/Calendar')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/Login')
    },
    {
        path: '/autologin',
        name: 'autologin',
        component: () => import('./views/Autologin')
    },
    {
        path: '/confirmar-reserva',
        name: 'confirmar_reserva',
        component: () => import('./views/ConfirmarReserva')
    }

]

const router = new VueRouter({
    routes
})

export default router
