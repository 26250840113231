import axios from 'axios'
import Vue from "vue";

const state = {
    show_toolbar: false
}

const mutations = {
    set_show_toolbar(state, show_toolbar){
        state.show_toolbar = show_toolbar
    }
}

const actions = {

    /**
     * Devuelve el código postal a partir de unas coordenadas
     * @returns {Promise<any>}
     */
    async get_postal_code({commit}, {lat, lng}){
        try{
            commit('set_loading', true, {root: true})

            const api = axios.create({
                baseURL: 'https://maps.googleapis.com'
            })
            const {data} = await api({
                method: 'GET',
                url: `/maps/api/geocode/json?latlng=${lat},${lng}&sensor=false&key=AIzaSyDmUD6uH6iMxcckUKlsBj6BTc-0z4NIInI`
            })
            const results = data['results']

            let postal_code = null;
            if (results[0]) {
                for (let i = 0; i < results[0].address_components.length; i++) {
                    let types = results[0].address_components[i].types;

                    for (let typeIdx = 0; typeIdx < types.length; typeIdx++) {
                        if (types[typeIdx] == 'postal_code') {
                            postal_code = results[0].address_components[i].long_name
                            break;
                        }
                    }
                }
            }

            return postal_code
        }catch (err){
        }finally {
            commit('set_loading', false, {root: true})
        }
    },

    /**
     * Devuelve unas coordenadas a partir de un código postal
     * @returns {Promise<any>}
     */
    async get_coordinates_from_postal_code({commit}, postal_code){
        try{
            commit('set_loading', true, {root: true})

            const api = axios.create({
                baseURL: 'https://maps.googleapis.com'
            })


            const {data} = await api({
                method: 'GET',
                url: `/maps/api/geocode/json?address=spain%20${postal_code}&sensor=false&key=AIzaSyDmUD6uH6iMxcckUKlsBj6BTc-0z4NIInI`
            })

            const results = data['results']

            if (results[0]) {
                try{
                    if(results[0].geometry.location){
                        return results[0].geometry.location
                    }
                }catch (err){}
            }

            return null

        }catch (err){

        }finally {
            commit('set_loading', false, {root: true})
        }


    },

    /**
     * Envía las estadísiticas de abandono de la web
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async send_stat({commit}, data){
        await Vue.axios({
            method: 'POST',
            url: `/public/stats/reservas`,
            data
        })
    },
}


export default {
    namespaced: true,
    state,
    mutations,
    actions
}
