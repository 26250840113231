import Vue from 'vue'

const state = {
    centro: null
}

const mutations = {
    set_centro(state, centro){
        state.centro = centro
    },
}

const actions = {

    /**
     * Recupera los centros de la API
     * @returns {Promise<any>}
     */
    async get_centros({commit}, params){

        const {data} = await Vue.axios({
            method: 'GET',
            url: `/public/centros`,
            params
        })
        return data
    },

    async get_centro({commit}, slug){
        const {data} = await Vue.axios({
            method: 'GET',
            url: `/public/centro/${slug}`
        })

        // Establecemos todos los servicios no visibles para el buscador
        data.servicios.forEach((current_servicio) => {
            current_servicio.visible = false
        })
        commit('centro/set_centro', data, {root: true})

        return data
    },

    async get_zonas() {
        const {data} = await Vue.axios({
            method: 'GET',
            url: `/public/zonas`
        })

        return data
    }

}


export default {
    namespaced: true,
    actions,
    state,
    mutations
}
