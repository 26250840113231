import router from "@/router";

const get_utms = () => {
  const utm_source = router.currentRoute.query.utm_source
  const utm_medium = router.currentRoute.query.utm_medium
  const utm_campaign = router.currentRoute.query.utm_campaign

  return { utm_source, utm_medium, utm_campaign }

}

export default get_utms