import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import PARAMETERS from '@/config/parameters'
import store from '../store';

const baseURL = `${PARAMETERS.API_BASE_URL}${PARAMETERS.API_BASE_SUFFIX}`

axios.defaults.baseURL = baseURL

axios.interceptors.request.use(function (config) {
    // Activamos el loading
    store.commit('set_loading', true)

    config.headers.Authorization = `Bearer ${PARAMETERS.ACCESS_TOKEN}`

    if (config.url.includes('/private/')) {
        const token = store.state.auth.token
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
    }
    
    return config
}, function (err) {
    return Promise.reject(err)

})


// Response interceptor
axios.interceptors.response.use(function(response) {
    // Desactivamos el loading
    store.commit('set_loading', false)

    return response
}, function (err){
    // Desactivamos el loading
    store.commit('set_loading', false);

    return Promise.reject(err)
})

Vue.use(VueAxios, axios)
